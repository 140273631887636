/* @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500&display=swap"); */
/* @import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500&display=swap"); */
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

* {
  margin: 0;
  /* font-family: "Poppins", "sans-serif"; */
  font-family: "Montserrat", sans-serif;
}
body {
  margin: 0;
  /* font-family: "Poppins", "sans-serif"; */
  font-family: "Montserrat", sans-serif;
}

.ant-btn-primary {
  background: #4f59b4 !important;
  color: white;
}

.Landing {
  background: url("./assets/blur/249.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  animation: slideBg 8s ease-in-out infinite 5s;
  animation-timing-function: ease-in-out;
  background-repeat: no-repeat;
  background-repeat: no-repeat;
}

.border-none {
  border: 0 !important;
}

@keyframes slideBg {
  20% {
    background: url("./assets/blur/794.jpg");
    background-size: cover;
  }

  40% {
    background: url("./assets/blur/grainy-gradients.jpg");
    background-size: cover;
  }

  /* 80% {
    background: url("./assets/blur/grainy-gradients.jpg");
    background-size: cover;
  } */

  100% {
    background: url("./assets/blur/249.jpg");

    background-size: cover;
  }
}

:where(.css-dev-only-do-not-override-1l8tm5b).ant-menu-light.ant-menu-horizontal
  > .ant-menu-item-selected::after,
:where(.css-dev-only-do-not-override-1l8tm5b).ant-menu-light
  > .ant-menu.ant-menu-horizontal
  > .ant-menu-item-selected::after,
:where(.css-dev-only-do-not-override-1l8tm5b).ant-menu-light.ant-menu-horizontal
  > .ant-menu-submenu-selected::after,
:where(.css-dev-only-do-not-override-1l8tm5b).ant-menu-light
  > .ant-menu.ant-menu-horizontal
  > .ant-menu.ant-menu-horizontal
  > .ant-menu-item-active {
  border-bottom-width: 0;
}

/* .ant-menu-overflow-item
  .ant-menu-item-active
  .ant-menu-item
  .ant-menu-item-selected {
  border-bottom-width: 0;
} */

:where(.css-dev-only-do-not-override-1l8tm5b).ant-menu-light.ant-menu-horizontal
  > .ant-menu-item:hover::after,
:where(.css-dev-only-do-not-override-1l8tm5b).ant-menu-light
  > .ant-menu.ant-menu-horizontal
  > .ant-menu-item:hover::after,
:where(.css-dev-only-do-not-override-1l8tm5b).ant-menu-light.ant-menu-horizontal
  > .ant-menu-submenu:hover::after,
:where(.css-dev-only-do-not-override-1l8tm5b).ant-menu-light
  > .ant-menu.ant-menu-horizontal
  > .ant-menu-submenu:hover::after,
:where(.css-dev-only-do-not-override-1l8tm5b).ant-menu-light.ant-menu-horizontal
  > .ant-menu-item-active::after,
:where(.css-dev-only-do-not-override-1l8tm5b).ant-menu-light
  > .ant-menu.ant-menu-horizontal
  > .ant-menu-item-active::after,
:where(.css-dev-only-do-not-override-1l8tm5b).ant-menu-light.ant-menu-horizontal
  > .ant-menu-submenu-active::after,
:where(.css-dev-only-do-not-override-1l8tm5b).ant-menu-light
  > .ant-menu.ant-menu-horizontal
  > .ant-menu-submenu-active::after,
:where(.css-dev-only-do-not-override-1l8tm5b).ant-menu-light.ant-menu-horizontal
  > .ant-menu-item-open::after,
:where(.css-dev-only-do-not-override-1l8tm5b).ant-menu-light
  > .ant-menu.ant-menu-horizontal
  > .ant-menu-item-open::after,
:where(.css-dev-only-do-not-override-1l8tm5b).ant-menu-light.ant-menu-horizontal
  > .ant-menu-submenu-open::after,
:where(.css-dev-only-do-not-override-1l8tm5b).ant-menu-light
  > .ant-menu.ant-menu-horizontal
  > .ant-menu-submenu-open::after {
  border-bottom-width: 0px;
  border-bottom-color: 0;
  border-bottom: 0;
}

.ant-layout-sider-children {
  width: 200px;
}

.css-dev-only-do-not-override-2ncrtl.ant-menu-light.ant-menu-horizontal
  > .ant-menu-item-selected::after,
.css-dev-only-do-not-override-2ncrtl.ant-menu-light
  > .ant-menu.ant-menu-horizontal
  > .ant-menu-item-selected::after,
.css-dev-only-do-not-override-2ncrtl.ant-menu-light.ant-menu-horizontal
  > .ant-menu-submenu-selected::after,
.css-dev-only-do-not-override-2ncrtl.ant-menu-light
  > .ant-menu.ant-menu-horizontal
  > .ant-menu-submenu-selected::after {
  border-bottom-width: 0;
  border-bottom-color: "";
}
